import Card from 'react-bootstrap/Card';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormSelect } from '@coreui/react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';




const urlRouter = '/accounts';
const urlApi = '/accounts';

export async function LoaderAccountsView({ params }) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
        return response;
    });
    return res;
};


const AccountsView = () => {
    const { data } = useLoaderData();
    return (<>
        {(data.manager == undefined)&& <Alert variant="danger">Увага! Контрагент не має вказаного менеджера.</Alert>}
        {(data.user == undefined)&& <Alert variant="danger">Увага! Контрагент не має вказаного користувача.</Alert>}

        <Card>
            <Card.Header>
                <div className="row">
                    <div className="col-10">
                        <h2>{data.name}</h2>
                    </div>
                    <div className="col-2 d-flex justify-content-end"> 
                        <Link to={`${urlRouter}/update/${data.id}`}><CButton color="dark" variant="outline" title="Редагувати контрагента">Редагувати <CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>
                    </div>
                </div>

            </Card.Header>
            <Card.Body>
                <Table striped>
                    <tbody>
                        {(data.type != undefined) && <tr> <th>Тип</th> <td>{data.type}</td> </tr> }
                        {( data.ipn != undefined) && <tr> <th>ІПН</th> <td>{data.ipn}</td> </tr> }
                        {( data.edrpou != undefined) && <tr> <th>ЄДРПОУ</th> <td>{data.edrpou}</td> </tr> }
                        {( data.code1c != undefined) && <tr> <th>Код-1с</th> <td>{data.code1c}</td> </tr> }
                        {( data.user != undefined) && <tr> <th>Користувач</th> <td>{data.user.username} - {data.user.firstName}</td> </tr> }
                        {( data.manager != undefined) && <tr> <th>Менеджер</th> <td>{data.manager.username} - {data.manager.firstName}</td> </tr> }
                        {( data.contacts != undefined) && <tr> <th>Контактна інф.</th> <td>
                            <ul>
                                {data.contacts.map((с)=>(
                                    <li>{с.value}</li>
                                ))}
                            </ul>
                        </td> </tr> }
                        {( data.agreements != undefined) && <tr> <th>Договори</th> <td>
                            <ul>
                                {data.agreements.map((a)=>(
                                    <li>{a.name} - {a.code1c}</li>
                                ))}
                            </ul>
                        </td> </tr> }
                    </tbody>
                </Table>
                
            </Card.Body>
        </Card>
        <hr/>
        {(data != undefined  && data.user != undefined) &&
            <Alert variant="success">
                <Alert.Heading>Користувач {data.user.username}</Alert.Heading>
                <hr/>
                <p>
                    Заходьте в наш кабінет<br/>
                    https://cabinet.td-kramar.com/<br/>
                    Ця платформа дає можливість:<br/>
                    - переглядати інформацію про поточні акції і промо<br/>
                    - переглядати весь діючий асортимент<br/>
                    - оформити замовлення в онлайн режимі<br/>
                    - переглянути акт звірки <br/>
                    - переглядати історію замовлень і здійснювати повторне замовлення<br/> 
                </p>
                <hr/>
                <Alert.Link href={`/users/${data.user.id}`} target="_blanck">Редагуати користувача</Alert.Link>
            </Alert>
                        
        }
        
    </>);
}

export default AccountsView;