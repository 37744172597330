import Alert from 'react-bootstrap/Alert';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { useEffect, useState } from 'react';
import AxiosInstance from '../Axios';


const  CreateUser = ({account}) => {
    console.log(account);
    const [user, setUser] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState();
     /** генерування паролю */
    const [passwordLength, setPasswordLength] = useState(6);
    const [useSymbols, setUseSymbols] = useState(true);
    const [useNumbers, setUseNumbers] = useState(true);
    const [useLowerCase, setUseLowerCase] = useState(true);
    const [useUpperCase, setUseUpperCase] = useState(true);
    const [successMessage, setSuccessMessage] = useState("");
    const generatePassword = () => {
        let charset = "";
        let newPassword = "";

        

        // if (useSymbols) charset += ".-?";
        if (useNumbers) charset += "0123456789";
        if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
        // if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        for (let i = 0; i < passwordLength; i++) {
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        }

        setPassword(newPassword);
    };
    useEffect(() => {
        generatePassword();
        if(account.edrpou != '' && account.edrpou != null){
            setUserName(account.edrpou);
        }else if(account.ipn != '' && account.ipn != null ){
            setUserName(account.ipn);
        }else if(account.phone != '' && account.phone != null){
            setUserName(account.phone);
        }else if(account.code1c != '' && account.code1c != null){
            setUserName(account.code1c);
        }
    },[])
    const CreateUser = () => {
        // if(account.edrpou != null && account.edrpou != ''){
            const data_response = {
                username: userName,
                plainPassword: password,
                firstName: account.name,
                roles: ["ROLE_CLIENT"],
                active: 'on',
                accounts: [`/api/accounts/${account.id}`],
                status: 'createdManager'
            };

            const response = AxiosInstance.post(`/users`, data_response).then((response) => {
                setUser(response.data);
            });
        // }else{
        //     alert('Не можна створити користувача поки не зповнений ЄДРПОУ');
        // }
    }

    return (
        <>
            {(!user) &&
                <Alert variant="warning">
                    <h3>Увага!</h3>
                    <p>Даний контрагент не має заданого користувача!</p>
                    <p>Якщо користувач вже є створений в системі от можете його прикріпити нижче в пункті <b>Користувач (клієнт)</b>.</p>
                    <p>Або можете створити автоматично нового користувача! 

                    <CButton
                        className="mb-3 me-3" color="secondary"
                        onClick={() => CreateUser()}
                        >
                        Створити користувача
                    </CButton>
 
                    </p>



                </Alert>
            }
            {(user) && 
                <Alert variant="success">
                    <Alert.Heading>Користувача створено!</Alert.Heading>
                    <hr/>
                    <p>
                        Заходьте в наш кабінет<br/>
                        https://cabinet.td-kramar.com/<br/>
                        Ця платформа дає можливість:<br/>
                        - переглядати інформацію про поточні акції і промо<br/>
                        - переглядати весь діючий асортимент<br/>
                        - оформити замовлення в онлайн режимі<br/>
                        - переглянути акт звірки <br/>
                        - переглядати історію замовлень і здійснювати повторне замовлення<br/> 
                    </p>
                    <p>Логін: {user.username}</p>
                    <p>Пароль: {password}</p>
                    <hr/>
                    <Alert.Link href={`/users/${user.id}`} target="_blanck">Редагуати користувача</Alert.Link>
                    
                </Alert>
            }
        </>
    );
}

export default CreateUser;