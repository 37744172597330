import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormSelect } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import useOpenAccordion from 'src/components/store/useOpenAccordion';
import dayjs from 'dayjs';

const page = 1;
const postPerPage = 0;
const pageSize = 20;
const urlApi = '/users';
const urlRouter = '/users';

const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));

export async function LoaderUsers({ params }) {
  let query = `page=${page}`;
  if (role == 'ROLE_MANAGER')
    query += `&accounts.manager.id=${userData.id}`;

  let res = await AxiosInstance.get(`${urlApi}?${query}`).then((response) => {
    return response;
  });
  return res;
}

const roleList = {
  ROLE_ADMIN: 'Адміністратор',
  ROLE_MANAGER: 'Менеджер',
  ROLE_CLIENT: 'Клієнт',
  ROLE_USER: 'Без ролі',
};

const Users = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [usernameFilterSearch, setUsernameFilterSearch] = useState('');
  const [firstNameFilterSearch, setFirstNameFilterSearch] = useState('');
  const [lastNameFilterSearch, setLastNameFilterSearch] = useState('');
  const [sortByLoginCnt, setSortByLoginCnt] = useState('');
  const [sortByLoginDate, setSortByLoginDate] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);

  // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
  const activeAccordionItem = useOpenAccordion();
  const activeItem = activeAccordionItem.activeItem;

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Користувача видалено";

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data['hydra:member']);
  };

  // Запит за попередніми замовленнями (+фільтр)
  const getRowsTable = (p = 1) => {
    let request = {page: p};
    if (usernameFilterSearch != '') {
      request["username"] = usernameFilterSearch;
    };
    if (firstNameFilterSearch != '') {
      request["firstName"] = firstNameFilterSearch;
    };
    if (lastNameFilterSearch != '') {
      request["lastName"] = lastNameFilterSearch;
    };
    if (role == 'ROLE_MANAGER')
      request["accounts.manager.id"] = userData.id;

    if(sortByLoginDate != '' ){
      /** order[name]=desc  */
      request['order[lastLogin]'] = sortByLoginDate;
    }
    
    if(sortByLoginCnt != '' ){
      /** order[name]=desc  */
      request['order[loginCount]'] = sortByLoginCnt;
    }


    // console.log("request", request);
    AxiosInstance.get(`${urlApi}`, {params: request}).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = event => {
    if (event.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    };
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (usernameFilterSearch != "" || firstNameFilterSearch != "" || lastNameFilterSearch != "" || sortByLoginCnt != '' || sortByLoginDate != '') {
      setDisabled(false);
    };
  }, [usernameFilterSearch, firstNameFilterSearch, lastNameFilterSearch,  sortByLoginCnt, sortByLoginDate]);


  // ----- Start handleFilterChange -----
  const handleUsernameFilterChange = event => {
    setUsernameFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleFirstNameFilterChange = event => {
    setFirstNameFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleLastNameFilterChange = event => {
    setLastNameFilterSearch(event.target.value);
    disabledButton(event);
  };
  // ----- End handleFilterChange -----

  // Фільтрування по userName, firstName та lastName
  const handleFilterSearch = () => {
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setUsernameFilterSearch("");
    setFirstNameFilterSearch("");
    setLastNameFilterSearch("");
    setDisabled(true);
    getRowsTable();
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(urlApi + '/' + row.id, header).then((response) => {
              // Popup про успішні зміни
              popup.toastifySuccess(popupTextSuccess);
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'username', headerName: 'username', width: 200,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/${params.row.id}`} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>{params.row.username}</Link>;
      }
    },
    { field: 'firstName', headerName: 'Ім\'я', width: 200 },
    { field: 'lastName', headerName: 'Прізвище', width: 200 },
    {
      field: "login",
      headerName: "Вхід",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        let html = '';
        if(params.row.lastLogin != undefined)
          html += dayjs( params.row.lastLogin).format('DD.MM.YYYY');
        if( params.row.loginCount != undefined)
          html += "<br/>К-сть - "+ params.row.loginCount;

        return <div dangerouslySetInnerHTML={{ __html: html }} />;
      }
    },
    { field: 'phone', headerName: 'Телефон', width: 170 },
    {
      field: "role",
      headerName: "Роль",
      sortable: false,
      width: 130,
      renderCell: (params) => {
        let role = '';
        if (typeof params.row.roles == 'object')
          role = params.row.roles.map((e) => {
            if (e != 'ROLE_USER')
              return roleList[e];
          });
        return role;
      }
    },
    // { field: 'address', headerName: 'Адреса', width: 200 },
    { field: 'status', headerName: 'Статус', width: 120 },
    // { field: 'codeUser', headerName: 'codeUser', width: 200 },
    { field: 'codeManager', headerName: 'codeManager', width: 150 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    // {
    //   field: "delete",
    //   headerName: "Видалити",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return <CButton color="danger" variant="outline" onClick={() => {
    //       Deleted(params.row)
    //     }
    //     }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
    //   }
    // },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion key={activeItem} activeItemKey={activeItem} flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="username" id="usernameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleUsernameFilterChange} value={usernameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="Ім'я" id="firstNameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleFirstNameFilterChange} value={firstNameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="Прізвище" id="lastNameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleLastNameFilterChange} value={lastNameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                  </CCol>
                </CRow>
                <CRow className="my-3 mx-2">
                  <CCol className="col-1">
                    Сортування:
                  </CCol>
                  <CCol  className="col-1">
                    Дата логування:
                    <CFormSelect
                        aria-label="Default select example"
                        onChange={(e) => {setSortByLoginDate(e.target.value)}}
                        value={sortByLoginDate}
                        options={[
                            {value: '', label: ' - '},
                            {value: 'asc', label: 'від Збільшення до зменшення'}, 
                            {value: 'desc', label: 'від Зменшення до збільшення'},
                        ]}
                    />
                  </CCol>
                  <CCol  className="col-1">
                    К-сть логування:
                    <CFormSelect
                        aria-label="Default select example"
                        onChange={(e) => {setSortByLoginCnt(e.target.value)}}
                        value={sortByLoginCnt}
                        options={[
                            {value: '', label: ' - '},
                            {value: 'asc', label: 'від Збільшення до зменшення'}, 
                            {value: 'desc', label: 'від Зменшення до збільшення'},
                        ]}
                    />
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardHeader>
            <strong>Користувачі</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Users;