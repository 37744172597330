import { useState, useEffect } from "react";
import ChooseProduct from "./ChooseProduct/ChooseProduct";
// import ChooseProduct from "./ChooseProduct(loadOptions)";
import ProductInfo from "./ProductInfo";
import AxiosInstance from 'src/components/Axios';
import ViewProductOrders from "./ViewProductOrders/ViewProductOrders";
import UpdateProductItem from "./UpdateProductItem";
import { CButton } from "@coreui/react";
import { useSelector } from "react-redux";

// Модальне вікно для додавання товару до замовлення 
const AddProductToOrder = (props) => {
    const reduxPrice = useSelector(state => state.price)
    // const [isSale, setIsSale] = useState(false);

    // const [sale, setSale] = useState(0);
    // console.log("redux ", reduxPrice); 
    const urlApi = '/order_products';
    // Вибраний товар з ChooseProduct
    const [selectedProductId, setSelectedProductId] = useState(false);
    // Доданий товар
    const [addedProduct, setAddedProduct] = useState(false);
    // Дані про товар, які будуть додані до замовлення
    const [productData, setProductData] = useState(false);
    // Показати таблицю з доданими товарами до замовлення або продукт
    const [showTable, setShowTable] = useState(true);
    // Товари, які є в списку замовлення
    const [products, setProducts] = useState(false);
    // Відкрити/закрити вікно для оновлення даних товару (кількість)
    const [openUpdateProduct, setOpenUpdateProduct] = useState(false);
    // Продукт для оновлення кількості
    const [productForUpdate, setProductForUpdate] = useState(false);
    // Текст для popup
    const popupTextSuccess = "Товар додано";

    // useEffect(() => {
    //     console.log("props.productDataQuick", props.productDataQuick);
    // }, [props.productDataQuick]);

    // useEffect(() => {
    //     if (productForUpdate != false) {
    //         console.log("productForUpdate", productForUpdate);
    //     }
    // }, [productForUpdate]);

    // useEffect(() => {
    //     console.log("openUpdateProduct", openUpdateProduct);
    // }, [openUpdateProduct]);

    // Коли натиснути оновлення товару, змінити showTable на false
    useEffect(() => {
        if (openUpdateProduct === true) {
            setShowTable(false);
        }
    }, [openUpdateProduct]);

    // Функція для додавання товару
    const addToOrder = (product) => {
        setAddedProduct(product);
    };

    // Передати товар для формування даних про товар для додавання до замовлення
    useEffect(() => {
        if (addedProduct != false) {
            // console.log("addedProduct", addedProduct);
            ordersProducts(addedProduct);
        }
    }, [addedProduct]);

    // Показати детальну інформацію про товар (ProductInfo) 
    useEffect(() => {
        if (selectedProductId != false) {
            // console.log("selectedProductId", selectedProductId);
            // Показати модуль з вибраним товаром
            setShowTable(false);
        };
    }, [selectedProductId]);

    // Формування даних про товар для додавання до замовлення

    // useEffect(()=> {
    //     if(reduxPrice != undefined){
    //         reduxPrice.map(item => {
    //             if(item.product.id == props.product.id){
    //                 setIsSale(true);
    //                 setSale(item.price)

    //             }
    //         })
    //     }
    // })

    // Функція для вибору ціни для додавання до товару залежно від кількості
    const selectPriceForProduct = (product) => {
        // console.log('product', product);
        // console.log('product.count', product.count);

        let priceForProduct = 0;

        // Якщо при додаванні товару була введена ціна
        if (product.newPrice !== "") {
            priceForProduct = product.newPrice;
            return priceForProduct;
        } else {
            // Інакше вибрати ціну в залежності від кількості товару
            // Ціна одиниці товару
            // const productPriceR = (product != undefined && product.price != undefined) ? product.price : '0';
            const productPriceG3 = product?.priceCategory?.gurt_4 ? product.priceCategory.gurt_4 : '0';
            const productPriceG2 = (product?.priceCategory?.gurt_3) ? product.priceCategory.gurt_3 : '0';
            const productPriceG1 = product?.priceCategory?.gurt_2 ? product.priceCategory.gurt_2 : '0';
            const productPriceVIP = product?.priceCategory?.gurt_1 ? product.priceCategory.gurt_1 : '0';

            if (product.priceCategory == undefined && product?.price) {
                priceForProduct = product.price;
            } else if (product?.priceCategory && product.count != undefined) {
                if (product.count < 100) {
                    priceForProduct = productPriceG3;
                } else if (product.count >= 100 && product.count < 500) {
                    priceForProduct = productPriceG2;
                } else if (product.count == 500 || product.count < 1000) {
                    priceForProduct = productPriceG1;
                } else if (product.count >= 1000) {
                    priceForProduct = productPriceVIP;
                };
            };

            // console.log('priceForProduct 1', priceForProduct);
            return priceForProduct
        };
    };

    const ordersProducts = (addedProduct) => {
        // Заокруглені значення до 2-х знаків після коми
        let isSale = false;
        let sale = 0;
        // console.log("addedProduct", addedProduct);
        if (reduxPrice != undefined && reduxPrice.length > 0) {
            reduxPrice.map(item => {
                // console.log('mapping redux')
                if (item.product.id == addedProduct.id) {
                    // setIsSale(true);
                    // setSale(item.price);
                    isSale = true;
                    sale = item.price;
                    // console.log('comparring', isSale);
                };
            });
        };

        // Функція для вибору ціни в залежності від кількості товару
        const productWithSelectedPrice = selectPriceForProduct(addedProduct);

        // const priceForOne = isSale ? sale : (addedProduct.price != undefined ? addedProduct.price.toFixed(2) : 0);
        const priceForOne = isSale ? sale : (productWithSelectedPrice != undefined ? productWithSelectedPrice.toFixed(2) : 0);
        const sumPrice = (priceForOne * addedProduct.count).toFixed(2);
        // console.log('added Product:',addedProduct);
        setProductData({
            "order": `api/orders/${props.data.id}`,
            "name": addedProduct.name,
            "product": "/api/products/" + addedProduct.id,
            "count": addedProduct.count,
            "measurementUnit": (addedProduct.measurement_unit != undefined) ? `/api/measurment_units/${addedProduct.measurement_unit.id}` : null,
            "discount": 0,
            "curency": "uah",
            "priceForOne": parseFloat(priceForOne),
            "sumOrdered": parseFloat(sumPrice),
            "storage": addedProduct.storage
        });
    };

    // Запит на API для додавання товару до замовлення
    useEffect(() => {
        if (productData != false) {
            // console.log("productData", productData);

            // Запит на /order_products по orders та product.id (чи такий товар вже додано до замовлення)
            AxiosInstance.get(`${urlApi}?order=${props.data.id}&product.id=${addedProduct.id}`).then((response) => {
                if (response.status === 200) {
                    // console.log("response get product.id", response);
                    if (response.data['hydra:totalItems'] === 0) {
                        // Додати товар до списку замовлення
                        AxiosInstance.post(`${urlApi}`, productData).then((response) => {
                            if (response.status === 201) {
                                // console.log("response", response);
                                // alert("Товар додано до замовлення");
                                // Popup про успішні зміни
                                props.toastifySuccess(popupTextSuccess);
                                // Оновити список order_products
                                props.setGetOP(true);
                                // Після додавання продукту, якщо відкрите модальне вікно, закрити інформацію про продукт і відобразити список з доданими товарами
                                setShowTable(true);
                                // Оновити дані замовлення
                                props.getOrder();
                            };
                        });
                    } else {
                        alert("Даний товар вже додано");
                    };
                };
            });
        };
    }, [productData]);

    // console.log("ordersProducts", ordersProducts);

    return (
        <div style={{ position: "relative" }}>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    {
                        (showTable) ?
                            <>
                                {/* Список з доданими товарами до замовлення */}
                                <div className="orders-viewProductOrders__container">
                                    <ViewProductOrders data={props.data} getOP={props.getOP} setGetOP={props.setGetOP} type="modal"
                                        // setSumOrder={props.setSumOrder} 
                                        getOrder={props.getOrder}
                                        setProducts={setProducts}
                                        setOpenUpdateProduct={setOpenUpdateProduct} setProductForUpdate={setProductForUpdate} toastifySuccess={props.toastifySuccess}
                                    />
                                </div>
                            </>
                            :
                            <>
                                {
                                    (!openUpdateProduct) ?
                                        // "Інформація про товар"
                                        <div className="orders-productInfo__container">
                                            <ProductInfo
                                                selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} addToOrder={addToOrder} setShowTable={setShowTable}
                                                setGetOP={props.setGetOP} products={products} toastifySuccess={props.toastifySuccess} />
                                        </div>
                                        :
                                        // "Оновити продукт"
                                        <div className="orders-updateProductItem__container">
                                            <UpdateProductItem
                                                product={productForUpdate} setShowTable={setShowTable} setGetOP={props.setGetOP}
                                                setOpenUpdateProduct={setOpenUpdateProduct} toastifySuccess={props.toastifySuccess}
                                                getOrder={props.getOrder} />
                                        </div>
                                }
                            </>
                    }
                </div>
                {/* Вибрати товар зі списку */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div className="orders-chooseProduct__container">
                        <ChooseProduct setSelectedProductId={setSelectedProductId} addToOrder={addToOrder} order={props.data} products={products} toastifySuccess={props.toastifySuccess}
                            getOrder={props.getOrder}
                        />
                    </div>
                </div>
            </div >
            <CButton
                className="me-1 orders-btn__close" type="submit" color="secondary"
                onClick={() => props.setShowModal(!props.showModal)} title="Завершити редагування"
            >
                Завершити редагування
            </CButton>
        </div >
    )
};

export default AddProductToOrder;