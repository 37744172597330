import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { roundTheNumber } from '../store/roundTheNumber';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

// import async from 'react-select/dist/declarations/src/async';

const urlApi = '/orders';

const status = [
    { value: 'is_forming', label: 'Формується' },
    { value: 'created', label: 'Створено клієнтом' },
    // { value: 'aproved', label: 'Підтверджено' },
    { value: 'aproved', label: 'Проведений' },
    { value: 'comlated', label: 'Виконано' },
    { value: 'synced_approved_in_1c', label: 'Синхронізувати і провести в 1С' },
    // { value: '', label: 'Позначено на видалення' },
]
const paymentMethod = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'cash', label: 'Готівка' },
    { value: 'bank', label: 'Банківський переказ' },
    // { value: 'bank', label: 'Мульти оплата' }
]
const curency = [
    // { value: 'paid', label: 'Оплачено' },
    // { value: 'before_payment', label: 'Перед оплата' },
    { value: 'uah', label: 'uah' },
    { value: 'usd', label: 'usd' },
    { value: 'eur', label: 'eur' }
]

const objOrderData = {
    account: '',
    agreement: '',
    manager: '',
    status: '',
    description: '',
    dateOrder: null,
    dateEntered: '',
    curency: '',
    paymentMethod: '',
    sumOrdered: 0,
    curencyRate: 0,
    discountTotal: 0,
    discountPercent: 0,
    shippingAddressCountry: '',
    shippingAddressCity: '',
    shippingAddressStreet: '',
    shippingAddressBuilding: '',
    shippingAddressDate: '',
    synced: false,
    code1c: '',
    dateEnteredIn1c: '',
    dateModifiedIn1c: '',
    status1c: '',
};


const ViewOrders = (props) => {
    const data = props.data;
    // console.log('ViewOrders data', data);
    const [order, setOrder] = useState(objOrderData);
    // console.log('order', order);

    useEffect(() => {
        if (data != null && data != undefined) {
            setOrder({
                ...order,
                account: data?.account ? data.account : '',
                agreement: data?.agreement ? data.agreement : '',
                manager: data?.manager ? data.manager : '',
                status: data?.status ? data.status : '',
                description: data?.description ? data.description : '',
                dateOrder: data?.dateOrder ? new Date(data.dateOrder).toISOString().slice(0, 10) : '',
                dateEntered: data?.dateEntered ? new Date(data.dateEntered).toISOString().slice(0, 10) : '',
                curency: data?.curency ? data.curency : '',
                paymentMethod: data?.paymentMethod ? data.paymentMethod : '',
                curencyRate: data?.curencyRate ? data.curencyRate : 0,
                discountPercent: data?.discountPercent ? data.discountPercent : 0,
                shippingAddressCountry: data?.shippingAddressCountry ? data.shippingAddressCountry : '',
                shippingAddressCity: data?.shippingAddressCity ? data.shippingAddressCity : '',
                shippingAddressStreet: data?.shippingAddressStreet ? data.shippingAddressStreet : '',
                shippingAddressBuilding: data?.shippingAddressBuilding ? data.shippingAddressBuilding : '',
                shippingAddressDate: data?.shippingAddressDate ? data.shippingAddressDate : '',
                synced: data?.synced ? data.synced : false,
                code1c: data?.code1c ? data.code1c : '',
                // dateEnteredIn1c: data?.dateEnteredIn1c ? dayjs.utc(data.dateEnteredIn1c).format('DD.MM.YYYY, HH:mm:ss') : '',
                dateEnteredIn1c: data?.dateEnteredIn1c ? dayjs.utc(data.dateEnteredIn1c).format('YYYY-MM-DD, HH:mm:ss') : '',
                dateModifiedIn1c: data?.dateModifiedIn1c ? dayjs.utc(data.dateModifiedIn1c).format('YYYY-MM-DD, HH:mm:ss') : '',
                status1c: data?.status1c ? data.status1c : '',
                sumOrdered: data?.sumOrdered ? data.sumOrdered : '',
            })
        }
    }, [data]);

    // // Сума замовлення
    // const [sum, setSum] = useState("");
    // // Заокруглити суму до 2-х знаків після коми
    // useEffect(() => {
    //     setSum(roundTheNumber(props.sumOrder));
    // }, [props.sumOrder]);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" colSpan={3}>
                                <p className='m-0'>Контрагент: <strong>{order.account.name}</strong></p>
                                <p className='m-0'>Код контрагента 1С: <strong>{order.account.code1c}</strong></p>
                                <p className='m-0'>Договір: <strong>{order.agreement.name}</strong></p>
                                <p className='m-0'>Менеджер: <strong>{`${order.manager.firstName} ${order.manager.lastName}`}</strong></p>
                                <p className='m-0'>
                                    Адреса: <strong>{`${order.shippingAddressCountry} ${order.shippingAddressCity} ${order.shippingAddressStreet} ${order.shippingAddressBuilding}`}</strong>
                                </p>
                                <p className='m-0'>Дата доставки: <strong>{order.shippingAddressDate}</strong></p>
                                <p className='m-0'>Дата виконання: <strong>{order.dateOrder}</strong></p>
                                <p className='m-0'>Метод оплати: <strong>{
                                    paymentMethod.map((v, k) => {
                                        if (order.paymentMethod == v.value)
                                            return v.label;
                                    })
                                }</strong></p>
                                {/* <p className='m-0'>Сума: <strong>{`${sum} ${order.curency}`}</strong></p> */}
                                <p className='m-0'>Сума: <strong>{`${order.sumOrdered} ${order.curency}`}</strong></p>
                                {/* <p className='m-0'>Валюта: <strong>{order.curencyRate}</strong></p> */}
                                <p className='m-0'>Знижка: <strong>{order.discountPercent}</strong></p>
                                <p className='m-0'>Статус: <strong>{
                                    status.map((v, k) => {
                                        if (order.status == v.value)
                                            return v.label;
                                    })
                                }</strong></p>
                                <p className='m-0'>Опис: <span dangerouslySetInnerHTML={{ __html: order.description }}></span></p>
                            </TableCell>
                            <TableCell align="right">
                                <p className='m-0'>дата створення: <strong>{order.dateEntered}</strong></p>
                                <p className='m-0'>синхронізовано в 1с: <strong>{(order.synced) ? 'Так' : 'Ні'}</strong></p>
                                {/* {order.code1c !== "" && <p className='m-0'>Код накладної 1С: <strong>{order.code1c}</strong></p>} */}
                                <p className='m-0'>Код накладної 1С: <strong>{order.code1c}</strong></p>
                                {/* {order.dateEnteredIn1c !== "" && <p className='m-0'>Дата внесення в 1C: <strong>{order.dateEnteredIn1c}</strong></p>} */}
                                <p className='m-0'>Дата внесення в 1C: <strong>{order.dateEnteredIn1c}</strong></p>
                                {/* {order.dateModifiedIn1c !== "" && <p className='m-0'>Дата оновлення в 1C: <strong>{order.dateModifiedIn1c}</strong></p>} */}
                                <p className='m-0'>Дата оновлення в 1C: <strong>{order.dateModifiedIn1c}</strong></p>
                                {/* {order.status1c !== "" && <p className='m-0'>Статус в 1C: <strong>{order.status1c}</strong></p>} */}
                                <p className='m-0'>Статус в 1C: <strong>{order.status1c}</strong></p>
                            </TableCell>
                        </TableRow>

                        {/* <TableRow>
                            <TableCell align="left" colSpan={3}> */}
                        {/* <p className='m-0'>Статус: <strong>{
                                    status.map((v, k) => {
                                        if (order.status == v.value)
                                            return v.label;
                                    })
                                }</strong></p>
                                <p className='m-0'>Метод оплати: <strong>{
                                    paymentMethod.map((v, k) => {
                                        if (order.paymentMethod == v.value)
                                            return v.label;
                                    })
                                }</strong></p> */}
                        {/* <p className='m-0'>Валюта: <strong>{order.curencyRate}</strong></p>
                                <p className='m-0'>Знижка: <strong>{order.discountPercent}</strong></p> */}

                        {/* </TableCell>
                        </TableRow> */}

                        {/* <TableRow>
                            <TableCell>Статус:</TableCell>
                            <TableCell align="left"><strong>{
                                status.map((v, k) => {
                                    if (order.status == v.value)
                                        return v.label;
                                })
                            }</strong>
                            </TableCell> */}
                        {/* <TableCell align="right">дата виконання:</TableCell>
                            <TableCell align="left"><strong>{order.dateOrder}</strong></TableCell> */}
                        {/* </TableRow> */}
                        {/* <TableRow>
                            <TableCell>Адреса:</TableCell> */}
                        {/* <TableCell align="left" colSpan={3}>{`${data.shippingAddressCountry} ${data.shippingAddressCity} ${data.shippingAddressStreet} ${data.shippingAddressBuilding}`}</TableCell> */}
                        {/* <TableCell align="left" colSpan={3}>{order.shippingAddressCity}</TableCell>
                        </TableRow> */}
                        {/* <TableRow>
                            <TableCell>Опис:</TableCell>
                            <TableCell align="left" colSpan={3}><div dangerouslySetInnerHTML={{ __html: order.description }}></div></TableCell>
                        </TableRow> */}
                        {/* <TableRow>
                            <TableCell>Метод оплати:</TableCell>
                            <TableCell align="left" colSpan={3}><div>{
                                paymentMethod.map((v, k) => {
                                    if (order.paymentMethod == v.value)
                                        return v.label;
                                })
                            }</div></TableCell>
                        </TableRow> */}
                    </TableHead>
                    {/* <TableBody> */}
                    {/* {rows.map((row) => (
                                <TableRow key={row.desc}>
                                    <TableCell>{row.desc}</TableCell>
                                    <TableCell align="right">11111</TableCell>
                                    <TableCell align="right">222222</TableCell>
                                    <TableCell align="right">333333333</TableCell>
                                </TableRow>
                            ))} */}
                    {/* <TableRow>
                            <TableCell rowSpan={4} colSpan={2} />
                            <TableCell>Валюта</TableCell>
                            <TableCell><strong>{order.curencyRate}</strong></TableCell>
                        </TableRow> */}
                    {/* <TableRow>
                            <TableCell>Курс</TableCell>
                            <TableCell><strong>{order.curency}</strong></TableCell>
                        </TableRow> */}
                    {/* <TableRow>
                            <TableCell>Знижка</TableCell>
                            <TableCell><strong>{order.discountPercent}</strong></TableCell>
                        </TableRow> */}
                    {/* <TableRow>
                            <TableCell >Сума</TableCell>
                            <TableCell><strong>{sum} грн.</strong></TableCell>
                        </TableRow> */}
                    {/* </TableBody> */}
                </Table>
            </TableContainer>
        </>
    );
}

export default ViewOrders;