import { useState, useEffect } from "react";
import AxiosInstance from 'src/components/Axios';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { cilBasket, cilCheckCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

// Інформація про товар
const ProductInfo = (props) => {
    // console.log("ProductInfo props", props);

    const urlApi = '/products';
    const urlApi_2 = '/options';

    // Дані про продукт
    const [product, setProduct] = useState(false);
    // Зображення для продукту
    const media = (product.media != undefined && product.media.length > 0) ? product.media : "";

    // Ціни товару
    const productPriceR = product?.price ?? '0'; // Роздрібна
    const productPriceG3 = product?.priceCategory?.gurt_4 ?? '0'; // Гурт 3
    const productPriceG2 = product?.priceCategory?.gurt_3 ?? '0'; // Гурт 2
    const productPriceG1 = product?.priceCategory?.gurt_2 ?? '0'; // Гурт 1
    const productPriceVIP = product?.priceCategory?.gurt_1 ?? '0'; // Віп
    const productIncomePrice = product?.priceIncome ?? '0'; // Вхідна ціна одиниці товару
    // Чи даний товар є в списку замовлень
    const [added, setAdded] = useState(false);
    // Кількість товару
    const [counter, setCounter] = useState(1);
    const [count, setCount] = useState(1);
    const [newPrice, setNewPrice] = useState(""); // Нова ціна введена менеджером
    // Заблокувати кнопку "Додати до замовлення"
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [mainMedia, setMainMedia] = useState(false);
    // Індекс головного зображення з масиву media
    const [indexMainMedia, setIndexMainMedia] = useState(0);

    const [priceGroupArr, setPriceGroupArr] = useState(""); // Для "які ціни показувати"
    const optionKeyForPriceGroup = "show_price_group"; // Ключ з списком "які ціни показувати"

    // Які ціни показувати
    const [isShowGurt_3, setIsShowGurt_3] = useState(false);
    const [isShowGurt_2, setIsShowGurt_2] = useState(false);
    const [isShowGurt_1, setIsShowGurt_1] = useState(false);
    const [isShowVIP, setIsShowVIP] = useState(false);

    // Текст для popup
    const popupTextSuccess_1 = "Відкрито вікно з інформацією про товар";
    const popupTextSuccess_2 = "Вікно з інформацією про товар закрито";

    // Відслідковувати які ціни показувати
    useEffect(() => {
        // console.log('priceGroupArr', priceGroupArr);
        if (priceGroupArr.length > 0) {
            const groupArr = priceGroupArr.split(',').map(el => el.trim().toLowerCase());
            // console.log('groupArr', groupArr);

            setIsShowGurt_3(groupArr.includes("3"));
            setIsShowGurt_2(groupArr.includes("2"));
            setIsShowGurt_1(groupArr.includes("1"));
            setIsShowVIP(groupArr.includes("vip"));
        };
    }, [priceGroupArr]);

    // Функція для запиту за датою прайсу та які ціни показувати
    const getShowPriceGroup = async (key) => {
        try {
            const response = await AxiosInstance.get(`${urlApi_2}?option_key=${key}`);
            // console.log('response', response);
            setPriceGroupArr(response.data['hydra:member'][0].value)
        } catch (error) {
            console.error("Помилка запиту за 'які ціни показувати':", error);
        };
    };

    // Зробити запит за "які ціни показувати"
    useEffect(() => {
        getShowPriceGroup(optionKeyForPriceGroup);
    }, []);

    // Вивести повідомлення коли відкривається вікно з інформацією про товар
    useEffect(() => {
        // Popup про успішні зміни
        props.toastifySuccess(popupTextSuccess_1);
    }, [props.selectedProductId]);

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    }
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        }
        else {
            setCounter(1);
        }
    };
    useState(() => {
        setCount(counter);
    }, [counter]);

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log("inputChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number

        // Перетворити з типу string в тип number
        let value = parseFloat(event.target.value);
        let count = (value <= 0) ? 1 : value;
        setCounter(count);
    };

    // Перевірка counter на NaN
    useEffect(() => {
        // console.log('counter', counter);
        if (isNaN(counter)) {
            setDisabledBtn(true);
        } else {
            setDisabledBtn(false);
        };
    }, [counter]);

    // Функція для кількості (Зміна кількості шт. продуктів вручну)
    const notEmpty = (event) => {
        if (event.target.value === "") {
            setCounter(1);
        } else {
            return;
        };
    };

    // Виклик ф-ції для запиту за інформацією про продукт
    useEffect(() => {
        if (props.selectedProductId != false) {
            // console.log("props.selectedProductId", props.selectedProductId);
            getProduct();
        }
    }, [props.selectedProductId]);

    // Функція для запиту за інформацією про продукт
    const getProduct = () => {
        AxiosInstance.get(`${urlApi}/${props.selectedProductId}`, {
            params: {
                "g[0]": "product:read",
                "g[1]": "product:admin",
                "g[3]": "product:productInfos",
            }
        }).then((response) => {
            // console.log('response.data', response.data);
            // console.log("get /api/products/ response", response);
            setProduct(response.data);
        });
    };

    // Додавання товару до замовленя
    const handleAddToOrder = (product) => {
        // Вибрати склад
        const storage = product?.storageElement?.storage?.id ? `/api/storages/${product.storageElement.storage.id}` : "/api/storages/78";
        // Додати до продукту кількість штук count: counter
        const orderCount = { ...product, count: counter, storage: storage, newPrice: newPrice }
        // console.log('orderCount', orderCount);
        // Додавати товар в кошик замовлень
        props.addToOrder(orderCount);
    };

    // Функція для відкриття модального вікна з списком доданих товарів
    const changeModal = () => {
        // Очистити id продукта для перегляду
        props.setSelectedProductId(false);
        // Показати таблицю з доданими до замовлення товарами
        props.setShowTable(true);
        // Для запиту за списком з доданими товарами до замовлення
        props.setGetOP(true);
        // Popup про успішні зміни (закриття вікна)
        props.toastifySuccess(popupTextSuccess_2);
    };

    // Відслідковувати зміну додавання/видалення з кошика замовлень
    useEffect(() => {
        let isInArray = false;
        props.products.some(el => {
            // Порівняти id - чи є в кошику замовлень
            if (product != false && el.product.id === product.id) {
                isInArray = true;
            };
        });
        setAdded(isInArray);
    }, [product]);

    // Відслідковувати головне зображення продукта
    useEffect(() => {
        if (product != false && product.mainMedia != undefined && product.mainMedia != null) {
            setMainMedia(product.mainMedia);
        };
    }, [product]);

    // Визначити індекс головного зображення з масиву media
    useEffect(() => {
        // console.log('media', media);
        // console.log('mainMedia', mainMedia);
        if (media != undefined && media.length > 0 && mainMedia != undefined) {
            const index = media.findIndex(item => item.id === mainMedia.id);
            // console.log('index', index);
            if (index !== -1) {
                setIndexMainMedia(index);
            };
        };
    }, [media, mainMedia]);

    // Зміна ціни продукту вручну менеджером
    const priceChange = (event) => {
        // console.log("priceChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number
        let price = (event.target.value === "" || event.target.value < 0) ? 1 : parseFloat(event.target.value);
        setNewPrice(price);
    };

    return (
        <div style={{ position: "relative", padding: "10px" }}>
            <h2 className="mb-3 mx-2 orders-tableHeader">Інформація про товар</h2>
            <span className="orders-productInfo__btn-close" onClick={changeModal} title="Закрити вікно інформації про товар">X</span>
            <h6>{product.name}</h6>

            <div className="row">
                <div className="col-xxl-8 col-xl-6">
                    {
                        (media != '') ?
                            <Carousel
                                className="me-3" style={{ height: '200px', width: 'auto' }}
                                showArrows={false} showThumbs={true} showStatus={false} thumbWidth={60}
                                selectedItem={indexMainMedia}
                            >
                                {
                                    // Відобразити всі зображення до продукту
                                    (media != '') &&
                                    media.map(el => {
                                        return (
                                            <div key={el.id}>
                                                <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''} />
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                            :
                            <div className="me-3 orders-updateProductItems__photo">
                                <img className="me-3" src={"/no-img.jpg"} height={200} />
                            </div>
                    }
                </div>
                <div className="col-xxl-4 col-xl-6">
                    <div className="orders-productInfo__text">Артикул: {product.article}</div>
                    <div className="orders-productInfo__text">Штрихкод: {product.barcode}</div>
                    {isShowGurt_3 && <div className="orders-productInfo__text">Гурт 3: {productPriceG3} грн.</div>}
                    {isShowGurt_2 && <div className="orders-productInfo__text">Гурт 2: {productPriceG2} грн.</div>}
                    {isShowGurt_1 && <div className="orders-productInfo__text">Гурт 1: {productPriceG1} грн.</div>}
                    {isShowVIP && <div className="orders-productInfo__text">VIP: {productPriceVIP} грн.</div>}
                    <div className="orders-productInfo__text">Вхідна ціна: {productIncomePrice} грн.</div>
                    <div className="orders-productInfo__text">Ціна: {productPriceR} грн.</div>
                    <div className="orders-productInfo__text">Код1с: {product.code1c}</div>
                    <div className="mt-2">
                        {
                            (!added) ?
                                // Якщо товар не доданий до замовлення
                                <div className="orders-productInfo__text">
                                    <div className="orders-productInfo__count">
                                        <button className='me-3' onClick={productDecrement} style={{ width: "45px" }}>-</button>
                                        <input className='me-3 text-center orders-productInfo__count-input' type="number" min="0,001" step="any" value={counter} onChange={inputChange} onBlur={notEmpty} />
                                        <button className='orders-productInfo__count-btn' onClick={productIncrement}>+</button>
                                    </div>
                                    <div className="orders-productInfo__price">
                                        <span>Призначена ціна, грн.: </span> <br />
                                        <input className='orders-productInfo__price-input' type="number" pattern="[0-9]*" value={newPrice} onChange={priceChange} />
                                    </div>

                                    <div className='mt-2 orders-productInfo__btn-container'>
                                        <button onClick={() => handleAddToOrder(product)} style={{ cursor: "pointer" }} disabled={disabledBtn}>
                                            <span className='me-2'>
                                                <CIcon icon={cilBasket} customClassName="nav-icon" height={18} />
                                            </span>
                                            <span>Додати</span>
                                        </button>
                                    </div>
                                </div>
                                :
                                // Якщо товар вже доданий до замовлення
                                <div className="orders-productInfo__text">
                                    <CIcon icon={cilCheckCircle} customClassName="nav-icon" height={18} style={{ color: "green" }} />
                                    <span className="ms-2">Даний товар вже доданий</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProductInfo;