import React, { useEffect, useState } from 'react';
import AxiosInstance from '../Axios';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';

// Акт звірок
const CalculationsOrders = (props) => {
    // console.log('CalculationsOrders props', props);

    const urlApi = '/calculations';
    const page = 1;
    const itemsPerPage = 30;

    const [rows, setRows] = useState([]);
    const [rowCountState, setRowCountState] = useState(0);
    const [showLoader, setShowLoader] = useState(false);

    // Функція для запиту за ...
    const getRowsTable = async (page) => {
        setShowLoader(true);
        try {
            const response = await AxiosInstance.get(`${urlApi}?page=${page}&itemsPerPage=${itemsPerPage}&orders.id=${props.order_id}`);
            // console.log('calculations get response', response);
            setRows(response.data['hydra:member']);
            setRowCountState(response.data['hydra:totalItems']);
            setShowLoader(false);
        } catch (error) {
            console.error("Помилка запиту за calculations: ", error);
            setShowLoader(false);
        };
    };

    // Виконати запит за calculations по замовленню
    useEffect(() => {
        if (props.order_id) {
            getRowsTable(page);
        }
    }, [props.order_id]);

    const handlePageChange = (page) => {
        getRowsTable(page + 1);
    };

    const columns = [
        { field: 'coming', headerName: 'Прихід', width: 80 },
        { field: 'discharge', headerName: 'Розхід', width: 80 },
        { field: 'finalBalance', headerName: 'Залишок', width: 80 },
        { field: 'movementDocument', headerName: 'Реєстратор', width: 350 },
        {
            field: 'date', headerName: 'Період', width: 100,
            renderCell: (params) => {
                return `${dayjs(params.row.date).format('DD.MM.YYYY')}`
            }
        },
        // { field: 'agreement', headerName: 'Договір', width: 70 },
        { field: 'agreementType', headerName: 'Вид Договору', width: 120 },
        { field: 'typeMutualSettlements', headerName: 'Вид взаєморозрахунків', width: 180 },
        { field: 'oragnazationName', headerName: 'Організація', width: 150 },
    ];

    return (
        // <div style={{ height: 600, width: '100%' }}>
        <div style={{ height: 450, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={itemsPerPage}
                rowsPerPageOptions={[itemsPerPage]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
                loading={showLoader}
            />
        </div>
    );
};

export default CalculationsOrders;