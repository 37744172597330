import React, { useState } from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilSettings,
  cilNotes,
  cilSpeedometer,
  cilImagePlus,
  cilHamburgerMenu,
  cilFindInPage,
  cilNewspaper,
  cilStar,
  cilStorage
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'
import { GiCannedFish, GiFactory, GiChecklist, GiShoppingCart, GiPayMoney } from 'react-icons/gi';
import { GoFileDirectory } from 'react-icons/go';
import { FaUsersCog, FaBoxes, FaLocationArrow } from 'react-icons/fa';
import { TbWorldWww } from 'react-icons/tb';
import { AiOutlineColumnWidth } from 'react-icons/ai';
import { BiCategoryAlt, BiLibrary, BiDollar } from 'react-icons/bi';
import { GrDocumentText } from 'react-icons/gr';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { SlSpeech } from 'react-icons/sl';




export function _nav_admin() {
  return [
    {
      component: CNavGroup,
      name: 'Замовлення',
      icon: <GiShoppingCart className='nav-icon' />,
      items: [
        {
          component: CNavItem,
          name: 'Онлайн замовлення',
          to: '/pre-orders',
          icon: <GiShoppingCart className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Розхідна накладна',
          to: '/orders',
          icon: <GiShoppingCart className="nav-icon" />,
        },
      ],
    },
    {
      component: CNavItem,
      name: 'Договори',
      to: '/agreements',
      // icon: <GiShoppingCart className="nav-icon" />,
      icon: <HiOutlineDocumentText className="nav-icon" />,
    },
    {
      component: CNavGroup,
      name: 'Товари',
      icon: <GiCannedFish className='nav-icon' />,
      items: [
        {
          component: CNavItem,
          name: 'Бібліотека товарів',
          to: '/products',
          icon: <BiLibrary className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Товари на сайті',
          to: '/site-products',
          icon: <TbWorldWww className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Список цін',
          to: '/products/prices',
          icon: <BiDollar className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Залишки',
          to: '/stage-balance',
          icon: <GiFactory className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Категорії',
          to: '/category',
          icon: <BiCategoryAlt className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Атрибути',
          to: '/attributes',
          icon: <BiCategoryAlt className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Купони',
          to: '/coupons',
          icon: <BiCategoryAlt className="nav-icon" />,
        },
      ],
    },
    {
      component: CNavItem,
      name: 'Контрагенти',
      to: '/accounts',
      icon: <GiFactory className="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Користувачі',
      to: '/users',
      icon: <FaUsersCog className="nav-icon" />,
    },

    {
      component: CNavGroup,
      name: 'Довідники',
      icon: <GoFileDirectory className='nav-icon' />,
      items: [
        {
          component: CNavItem,
          name: 'Одиниці виміру',
          to: '/measurment-unit',
          icon: <AiOutlineColumnWidth className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Склади',
          to: '/storage',
          icon: <FaBoxes className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Елементи складу',
          to: '/storage-element',
          icon: <FaBoxes className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Location',
          to: '/locations',
          icon: <FaLocationArrow className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Збори',
          to: '/fees',
          icon: <GiPayMoney className="nav-icon" />,
        },
      ],
    },
    {
      component: CNavItem,
      name: 'FAQ',
      to: '/faq',
      icon: <SlSpeech className='nav-icon' />,
    },
    {
      component: CNavItem,
      name: 'Новини',
      to: '/news',
      icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Сторінки',
      to: '/site-pages',
      icon: <CIcon icon={cilFindInPage} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Меню',
      to: '/menu',
      icon: <CIcon icon={cilHamburgerMenu} customClassName="nav-icon" />,
    }, {
      component: CNavItem,
      name: 'Медіа',
      to: '/media',
      icon: <CIcon icon={cilImagePlus} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Налаштування',
      to: '/options',
      icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Статистика',
      to: '/statistics',
      icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Корисні матеріали',
      to: '/useful-materials',
      icon: <BiLibrary className="nav-icon" />,
    },
  ];
}


export function _nav_manager() {
  return [
    {
      component: CNavGroup,
      name: 'Замовлення',
      icon: <GiShoppingCart className='nav-icon' />,
      items: [
        {
          component: CNavItem,
          name: 'Онлайн замовлення',
          to: '/pre-orders',
          icon: <GiShoppingCart className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Розхідна накладна',
          to: '/orders',
          icon: <GiShoppingCart className="nav-icon" />,
        },
      ],
    },
    {
      component: CNavItem,
      name: 'Договори',
      to: '/agreements',
      // icon: <GiShoppingCart className="nav-icon" />,
      icon: <HiOutlineDocumentText className="nav-icon" />,
    },
    {
      component: CNavGroup,
      name: 'Товари',
      icon: <GiCannedFish className='nav-icon' />,
      items: [
        {
          component: CNavItem,
          name: 'Бібліотека товарів',
          to: '/products',
          icon: <BiLibrary className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Залишки',
          to: '/stage-balance',
          icon: <GiFactory className="nav-icon" />,
        },
        {
          component: CNavItem,
          name: 'Список цін',
          to: '/products/prices',
          icon: <BiDollar className="nav-icon" />,
        },
        // {
        //   component: CNavItem,
        //   name: 'Товари на сайті',
        //   to: '/site-products',
        //   icon: <TbWorldWww className="nav-icon" />,
        // },
        // {
        //   component: CNavItem,
        //   name: 'Категорії',
        //   to: '/category',
        //   icon: <BiCategoryAlt className="nav-icon" />,
        // },
        // {
        //   component: CNavItem,
        //   name: 'Атрибути',
        //   to: '/attributes',
        //   icon: <BiCategoryAlt className="nav-icon" />,
        // },
      ],
    },
    {
      component: CNavItem,
      name: 'Контрагенти',
      to: '/accounts',
      icon: <GiFactory className="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Користувачі',
      to: '/users',
      icon: <FaUsersCog className="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Корисні матеріали',
      to: '/useful-materials',
      icon: <BiLibrary className="nav-icon" />,
    },
  ];
}