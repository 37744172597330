import React, {useState, useEffect} from 'react'
import { NavLink, useNavigate, Redirect  } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu, cilAccountLogout } from '@coreui/icons'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import { logo } from 'src/assets/brand/logo'
import { Window } from '@mui/icons-material'
import { FcBusinessman } from "react-icons/fc";
import { switchSidebarShow ,  setOptions, setDateOption} from 'src/REDUX/orderSlice'; 





const AppHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const navigate = useNavigate()
  const [userName, setUserName] = useState(false);
  const [userId, setUserId] = useState(false);
  useEffect(() => {
    setUserName(localStorage.getItem('user_name'));
    setUserId( JSON.parse( localStorage.getItem('user_data') ) );
  }, [])
  const Logout  = () => {
    localStorage.setItem('user', '');
    dispatch(setOptions([])); // Відправляємо ціни до Redux
    dispatch(setDateOption('')); // Відправляємо ціни до Redux

    window.location.replace("/");
  }

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(switchSidebarShow(sidebarShow ))}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto"> 
          <FcBusinessman size={25}/>
          {(userName && userName != null)?  
            <CNavItem>
              <CNavLink to={`/users/${userId.id}`} component={NavLink}>
                {userName} ⚙
              </CNavLink>
            </CNavItem>
          : ''
          }
        </CHeaderNav>
        <CHeaderNav>
        {/* {(userName && userName != null)? userName : ''} */}
        
          {/* <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilBell} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <CNavItem>
            <CNavLink href="#" onClick={Logout}>
              <CIcon icon={cilAccountLogout} size="lg" /> Вийти
            </CNavLink>
          </CNavItem>
          {/* <AppHeaderDropdown /> */}
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
