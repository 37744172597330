import React, { useEffect, useRef, useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import AxiosInstance from 'src/components/Axios';
import dayjs from 'dayjs';

const urlApi = '/agreements';
const page = 1;
const itemsPerPage = 30;


export async function LoaderAgreementId({ params }) {
  let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
    return response;
  });
  return res;
}


const AgreementId = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);

  // console.log("data AgreenemtId", data);
  useEffect(() => {
    if (!rows) {
      setRowCountState(data.calculations.length);
      setRows(data.calculations);
    }
  }, [])
  const getRowsTable = (p = 1) => {
    AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  // const handlePageChange = (page) => {
  //   getRowsTable(page + 1);
  // }

  // const day = dayjs();

  const columns = [
    { field: 'coming', headerName: 'Прихід', width: 70 },
    { field: 'discharge', headerName: 'Розхід', width: 150 },
    { field: 'finalBalance', headerName: 'Залишок', width: 150 },
    { field: 'movementDocument', headerName: 'Реєстратор', width: 350 },
    {
      field: 'date', headerName: 'Період', width: 150,
      renderCell: (params) => {
        return `${dayjs(params.row.date).format('DD.MM.YYYY')}`
      }
    },
    // { field: 'agreement', headerName: 'Договір', width: 70 },
    { field: 'agreementType', headerName: 'Вид Договору', width: 150 },
    { field: 'typeMutualSettlements', headerName: 'Вид взаєморозрахунків', width: 200 },
    { field: 'oragnazationName', headerName: 'Організація', width: 150 },
  ]

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Договір #{data.id} {data.account.name}</strong>
            </CCardHeader>
            <CCardBody>
              <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={itemsPerPage}
                  rowsPerPageOptions={[itemsPerPage]}
                  pagination
                  rowCount={rowCountState}
                  paginationMode="server"
                // onPageChange={handlePageChange}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default AgreementId;
